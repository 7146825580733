import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import gqlQueries from './gqlQueryUtil'
import { UserContext } from "./UserContext"


/**
 * DataProvider wrapper
 * 
 * @param props 
 * 
 * - queryVariables
 * - queryName
 */
const DataProvider = (props) => {
  const reFetchProps = Object.assign({}, props)
  return ( <ErrorBoundary onHasError={<ErrorBoundary><DataQuery { ...reFetchProps } /></ErrorBoundary>}><DataQuery { ...props } /></ErrorBoundary> )
}

const DataQuery = (props) => {
  const [userState, userStateUpdate] = React.useContext(UserContext)
  const { children, queryVariables, queryName, poll } = props

  const { subscribeToMore, ...result } = useQuery(gqlQueries[queryName].query, {fetchPolicy: "network-only", variables: queryVariables, pollInterval: poll * 60000} );
 
  let subscribeKey = false 
  let editAccess = false
  let passedResult = result
  if (!result.error && !result.loading && "data" in result){
    if (typeof result.data == "object" && "result" in result.data) {
      subscribeKey = result.data.result?.subscribeKey
      //update the GoogleCalFeed editAccess based off of the current user context
      if (result.data.result.__typename === "GoogleCalFeed") {
        const userEditAccess = (userState !== null) ? userState.editAccess||[] : []
        editAccess = userEditAccess.map(e => e.calendarId).includes(result.data.result.calendarId)
        passedResult = {
          ...result,
          data: {
            ...result.data,
            result: {
              ...result.data.result,
              editAccess: editAccess,
              data: result.data.result.data.map(e => { 
                return {
                  ...e,
                  title: e.title,
                  editurl: editAccess ? e.editurl : null
                }
              })
            }
          }
        }
      }

      // errRate used for error testing only
      if (props.errRate !== undefined) {
        if (Math.random() * 10 > props.errRate) {
          passedResult.data.result = undefined
        }
      }
    } 
  }

  useEffect( () => { 
    if (subscribeKey) {
      subscribeToMore({
        document: gqlQueries[queryName].subscription,
        variables: { subscribeKey: subscribeKey},
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return prev
          }
          return subscriptionData.data
        }
      })
    }
  }, [subscribeKey, queryName, subscribeToMore] )

  //pass the data, error, and loading props to all child elements
  return ( React.cloneElement(children, { ...passedResult }) )
}

/**
 * React Error Boundary to contain thrown errors to this DOM tree
 */
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      hasError: false
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // Log the error 
    console.error("Data Provider Error:", error, errorInfo, "Props:", this.props)
  }

  render() {
    if (this.state.hasError) {
      // If an UI is provided when an error occurs, render it.
      if (this.props.onHasError !== undefined) {
        console.log("Error Refetch Rendered:", this.props)
        return this.props.onHasError
      }
      // Otherwise, render the fallback UI.
      console.log("Error UI Rendered:", this.props)
      return <div>Error retrieving data, <button onClick={() => { window.location.reload() }} className="text-blue-600 hover:underline">click to reload</button></div>
    }
    
    return this.props.children; 
  }
}

export default DataProvider
