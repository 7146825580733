import * as React from "react"

export const UserContext = React.createContext()

export const UserProvider = ({ children }) => {
  const [userState, userStateUpdate] = React.useState(null)

  return (
    <UserContext.Provider
      value={[userState, userStateUpdate]}
    >
      {children}
    </UserContext.Provider>
  )
}