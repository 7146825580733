import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPencilAlt,
  faSignInAlt,
  faSignOutAlt,
  faUndo,
  faPlus,
} from "@fortawesome/free-solid-svg-icons"
import {
  faCalendar,
  faEye,
  faEyeSlash,
  faMap,
  faArrowAltCircleUp,
  faArrowAltCircleDown,
} from "@fortawesome/free-regular-svg-icons"

/**
 * A general purpose Button component that can function as either a button or link
 * based on the presence of the `url` prop (note that internal urls (e.g. `"/about"`) return
 * a Gatsby `<Link>` component; external urls return an `<a>` tag).
 */
const Button = props => {
  const {
    color = "primary", // see theme.extend.colors in tailwind.config for theme options
    url,
    onClick,
    icon,
    srText,
    additionalAttr,
    additionalClasses,
    overrideClasses,
    children,
  } = props

  const defaultButtonStyles = [
    `bg-${color}`,
    "bg-opacity-10",
    "dark:bg-opacity-100",
    `text-${color}`,
    `dark:text-white`,
    `hover:bg-opacity-30`,
    "dark:hover:bg-opacity-90",
    "transition",
    "block",
    "flex",
    "items-center",
    "justify-center",
    "h-auto",
    "rounded",
    "font-semibold",
    "whitespace-nowrap",
    "p-2",
  ]

  const genIcon = icon => {
    const iconClasses = ["inline-block", "h-4", "w-4", "flex", "items-center"]
    const icons = {
      arrowUp: (
        <FontAwesomeIcon
          icon={faArrowAltCircleUp}
          className={iconClasses.join(" ")}
        />
      ),
      arrowDown: (
        <FontAwesomeIcon
          icon={faArrowAltCircleDown}
          className={iconClasses.join(" ")}
        />
      ),
      calendar: (
        <FontAwesomeIcon icon={faCalendar} className={iconClasses.join(" ")} />
      ),
      hide: (
        <FontAwesomeIcon icon={faEyeSlash} className={iconClasses.join(" ")} />
      ),
      login: (
        <FontAwesomeIcon icon={faSignInAlt} className={iconClasses.join(" ")} />
      ),
      logout: (
        <FontAwesomeIcon
          icon={faSignOutAlt}
          className={iconClasses.join(" ")}
        />
      ),
      map: <FontAwesomeIcon icon={faMap} className={iconClasses.join(" ")} />,
      pencil: (
        <FontAwesomeIcon icon={faPencilAlt} className={iconClasses.join(" ")} />
      ),
      plus: <FontAwesomeIcon icon={faPlus} className={iconClasses.join(" ")} />,
      reset: (
        <FontAwesomeIcon icon={faUndo} className={iconClasses.join(" ")} />
      ),
      show: <FontAwesomeIcon icon={faEye} className={iconClasses.join(" ")} />,
    }
    return icons[icon]
  }

  const isInternalLink = url => {
    const urlRegExp = new RegExp("^(/)")
    return urlRegExp.test(url)
  }

  const innerMarkup = (
    <span className="pointer-events-none">
      {icon && <span className="mr-2">{genIcon(icon)}</span>}
      {srText && <span className="tw-sr-only">{srText}</span>}
      {children}
    </span>
  )

  return url ? (
    isInternalLink(url) ? (
      <Link
        data-type="call to action"
        className={
          overrideClasses
            ? overrideClasses
            : defaultButtonStyles?.concat(additionalClasses).join(" ")
        }
        to={url}
        {...additionalAttr}
      >
        {innerMarkup}
      </Link>
    ) : (
      <a
        data-type="call to action"
        className={
          overrideClasses
            ? overrideClasses
            : defaultButtonStyles?.concat(additionalClasses).join(" ")
        }
        href={url}
        {...additionalAttr}
      >
        {innerMarkup}
      </a>
    )
  ) : (
    <button
      data-type="call to action"
      className={
        overrideClasses
          ? overrideClasses
          : defaultButtonStyles?.concat(additionalClasses).join(" ")
      }
      onClick={onClick}
      {...additionalAttr}
    >
      {innerMarkup}
    </button>
  )
}

Button.propTypes = {
  /** Sets button background (30% opacity) and text color (100% opacity). Accepts tailwind bg-[color]-[num] classes as well as custom color values (i.e., either bg-blue-600 or bg-secondary).  */
  color: PropTypes.string,
  /** Determines whether rendered component is a `<button>` or `<a>` element. Internal links will return a React Router `<Link>` component. */
  url: PropTypes.string,
  /** Callback for `<button onClick={() => cb()}`(note: not rendered when `url` prop is present). */
  onClick: PropTypes.func,
  /** Renders svg icon from preset list of options. */
  icon: PropTypes.oneOf([
    "arrowUp",
    "arrowDown",
    "calendar",
    "hide",
    "login",
    "logout",
    "map",
    "pencil",
    "plus",
    "reset",
    "show",
  ]),
  /** Text to be rendered in the `<span className="tw-sr-only">` element. */
  srText: PropTypes.string,
  /** Additional attributes to be passed to the rendered component. */
  additionalAttr: PropTypes.object,
  /** Additional classes to be passed to the default rendered component (can be completely overriden using overrideClasses)*/
  additionalClasses: PropTypes.array,
  /** Overrides default classes (can be used to completely override default classes). */
  overrideClasses: PropTypes.string,
  /** Text to be rendered in the button. */
  children: PropTypes.any,
}

export default Button
