import { gql } from "@apollo/client"

/**
 * the gqlDataFragments object contains the GraphQL that
 * defines the data returned by both the query and subscription
 */
const gqlData = {
  cbordnetmenufeed: `
    calendarId
    calendarName
    editAccess
    data {
      id
      title
      description
      allday
      startdate
      enddate
      url
      editurl
      updated
      status
    }
  `,
  cbordnetmenufeedhours: `
    calendarId
    calendarName
    editAccess
    data {
      id
      allday
      startdate
      enddate
      editurl
      status
      source
    }
  `,
  googlecalfeed: `
    calendarId
    calendarName
    editAccess
    data {
      id
      title
      description
      allday
      startdate
      enddate
      url
      editurl
      updated
      status
    }
  `,
  googlecalfeedhours: `
    calendarId
    calendarName
    editAccess
    data {
      id
      allday
      startdate
      enddate
      editurl
      status
      source
    }
  `,
  libcalfeed: `
    data {
      id
      title
      description
      allday
      startdate
      enddate
      editurl
      status
      source
    }
  `,
  combinedrssnewsfeeds: `
    data {
      id
      title
      description
      url
      source
      published
      sticky
    }
  `,
  septatrainfeed: `
    data {
      id
      orig_train
      orig_line
      orig_departure_time
      orig_delay
    }
  `,
  swatcentralfeed: `
    data {
      title
      id
      url
      eventactionurl
      description
      startdate
      enddate
      allday
      eventtype
      formatteddate
      location
      organization
    }
  `,
  swatshuttleroutes: `
    data {
      id
      route
      route_id
    }
  `,
  swatshuttle: `
    data {
      id
      routeName: route
      routeId: route_id
      stops {
        id
        datetime
        route
        route_id
        stop
        stop_id
        time
      }
    }
  `,
  tricoshuttle: `
    data {
      id
      datetime
      destination
      origin
      scheduleDay
      time
    }
  `,
  weatherfeed: `
    data {
      created
      location
      current {
        id
        weather
        weatherImage
        text
        temp
        startTime
        periodname
      }
      forecast {
        id
        iconLinks
        periodname
        friendlyDate
        startTime
        tempLabel
        temp
        text
        weather
      }
    }
  `,
  criticalannouncement: `
    data {
      showMessage: campusmessageon
      title
      description
      url
    }
  `,
  mindcandyfeed: `
    data {
      id
      paragraphType
      title
      mcImageUrl
      mcImageAlt
      mcAbstractText
      mcLink
      mcLinkTitle
      revealedText
      mcTheme
      correctRevision
    }
  `,
}

const gqlQuery = {
  cbordnetmenufeed: gql`
    query onLoad ($calendarId: String!, $order: SortOrder, $timeMin: String, $timeMax: String, $maxResults: Int, $editAccess: Boolean, $timeWindow: String) {
      result: cbordnetmenufeed(calendarId: $calendarId, editAccess: $editAccess, order: $order, timeMin: $timeMin, timeMax: $timeMax, maxResults: $maxResults, timeWindow: $timeWindow){
        id
        subscribeKey
        ${gqlData.cbordnetmenufeed}
      }
    }
  `,
  cbordnetmenufeedhours: gql`
    query onLoad ($calendarId: String!, $order: SortOrder, $timeMin: String, $timeMax: String, $maxResults: Int, $editAccess: Boolean, $timeWindow: String, $status: String) {
      result: cbordnetmenufeed(calendarId: $calendarId, editAccess: $editAccess, order: $order, timeMin: $timeMin, timeMax: $timeMax, maxResults: $maxResults, timeWindow: $timeWindow, status: $status){
        id
        subscribeKey
        ${gqlData.cbordnetmenufeedhours}
      }
    }
  `,
  googlecalfeed: gql`
    query onLoad ($calendarId: String!, $order: SortOrder, $timeMin: String, $timeMax: String, $maxResults: Int, $editAccess: Boolean, $timeWindow: String) {
      result: googlecalfeed(calendarId: $calendarId, editAccess: $editAccess, order: $order, timeMin: $timeMin, timeMax: $timeMax, maxResults: $maxResults, timeWindow: $timeWindow){
        id
        subscribeKey
        ${gqlData.googlecalfeed}
      }
    }
  `,
  googlecalfeedhours: gql`
    query onLoad ($calendarId: String!, $order: SortOrder, $timeMin: String, $timeMax: String, $maxResults: Int, $editAccess: Boolean, $timeWindow: String, $status: String) {
      result: googlecalfeed(calendarId: $calendarId, editAccess: $editAccess, order: $order, timeMin: $timeMin, timeMax: $timeMax, maxResults: $maxResults, timeWindow: $timeWindow, status: $status){
        id
        subscribeKey
        ${gqlData.googlecalfeedhours}
      }
    }
  `,
  libcalfeed: gql`
    query onLoad ($calendarId: String!, $order: SortOrder, $timeMin: String, $timeMax: String, $maxResults: Int, $editAccess: Boolean, $timeWindow: String, $status: String) {
      result: libcalfeed(calendarId: $calendarId, editAccess: $editAccess, order: $order, timeMin: $timeMin, timeMax: $timeMax, maxResults: $maxResults, timeWindow: $timeWindow, status: $status){
        id
        subscribeKey
        ${gqlData.libcalfeed}
      }
    }
  `,
  combinedrssnewsfeeds: gql`
    query onLoad ($feedUrls: [String!]!, $feedStickyUrls: [String!]!, $maxResults: Int, $prevDaysToQuery: Int) {
      result: combinedrssnewsfeeds (feedUrls: $feedUrls, feedStickyUrls: $feedStickyUrls, maxResults: $maxResults, prevDaysToQuery: $prevDaysToQuery) {
        id
        subscribeKey
        ${gqlData.combinedrssnewsfeeds}
      }
    }
  `,
  septatrainfeed: gql`
    query onLoad ($departureStation: String!, $arrivalStation: String!, $maxResults: Int){
      result: septatrainfeed (departureStation: $departureStation, arrivalStation: $arrivalStation, maxResults: $maxResults) {
        id
        subscribeKey
        ${gqlData.septatrainfeed}
      }
    }
  `,
  swatcentralfeed: gql`
    query onLoad ($currentOnly: Boolean, $days: Int){
      result: swatcentralfeed (currentOnly: $currentOnly, days: $days) {
        id
        subscribeKey
        ${gqlData.swatcentralfeed}
      }
    }
  `,
  swatshuttleroutes: gql`
    query onLoad ($routeId: Int, $routeName: String, $isActive: Boolean){
      result: swatshuttleroutes (routeId: $routeId, routeName: $routeName, isActive: $isActive) {
        id
        subscribeKey
        ${gqlData.swatshuttleroutes}
      }
    }
  `,
  swatshuttle: gql`
    query onLoad ($showInactive: Boolean, $routeId: Int, $routeName: String){
      result: swatshuttle (routeId: $routeId, routeName: $routeName, showInactive: $showInactive) {
        id
        subscribeKey
        ${gqlData.swatshuttle}
      }
    }
  `,
  swatshuttlemap: gql`
    query onLoad($routeId: Int!) {
      result: swatshuttlemap(routeId: $routeId) {
        id
        contentType
        img
      }
    }
  `,
  tricoshuttle: gql`
    query onLoad ($destination: String!, $origin: String!, $currentOnly: Boolean, $maxResults: Int){
      result: tricoshuttle (destination: $destination, origin: $origin, currentOnly: $currentOnly, maxResults: $maxResults) {
        id
        subscribeKey
        ${gqlData.tricoshuttle}
      }
    }
  `,
  weatherfeed: gql`
    query {
      result: weatherfeed {
        id
        subscribeKey
        ${gqlData.weatherfeed}
      }
    }
  `,
  criticalannouncement: gql`
    query {
      result: criticalannouncement {
        id
        subscribeKey
        ${gqlData.criticalannouncement}
      }
    }
  `,
  mindcandyfeed: gql`
    query onLoad ($maxResults: Int, $drupalId: String, $resourceVersion: Int){
      result: mindcandyfeed (maxResults: $maxResults, drupalId: $drupalId, resourceVersion: $resourceVersion) {
        id
        ${gqlData.mindcandyfeed}
      }
    }
  `,
}

//Build the subscriptions based on key
let gqlQueries = {}
Object.keys(gqlQuery).map(key => {
  gqlQueries[key] = {
    query: gqlQuery[key],
    subscription: gql`
      subscription onUpdate ($subscribeKey: String!) {
        result: ${key}Update(subscribeKey: $subscribeKey) {
          id
          subscribeKey 
          ${gqlData[key]}
        }
      }
    `,
  }
  return key
})

export default gqlQueries
